import axios from "axios";

export default class BarChartService {
  static myInstance = null;
  url = process.env.REACT_APP_BACKEND_URL + "reports/";

  static getInstance() {
    if (BarChartService.myInstance == null) {
      this.myInstance = new BarChartService();
    }
    return this.myInstance;
  }

  getUsageReport(username, duration) {
    if (duration === "daily") {
      if (username === "" || username === "All Users")
        return axios.get(this.url + "dailyUsage");
      else return axios.get(this.url + "dailyUsage/" + username);
    } else if (duration === "monthly") {
      if (username === "" || username === "All Users")
        return axios.get(this.url + "monthlyUsage");
      else return axios.get(this.url + "monthlyUsage/" + username);
    }
  }
}
