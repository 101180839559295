import axios from "axios";

export default class EmailService {
  static myInstance = null;
  url = process.env.REACT_APP_BACKEND_URL + "users/emails";

  static getInstance() {
    if (EmailService.myInstance == null) {
      this.myInstance = new EmailService();
    }
    return this.myInstance;
  }

  async getAllEmails() {
    return axios.get(this.url);
  }
}
