import React, { Component } from "react";
import GroupsService from "./GroupsService";
import { DataGrid } from "@material-ui/data-grid";
import { Container, Box, Button } from "@material-ui/core";
import { Link } from "react-router-dom";

class Groups extends Component {
  groupsService = GroupsService.getInstance();

  constructor(props) {
    super(props);
    this.openUpdateGroupPage = this.openUpdateGroupPage.bind(this);
  }

  state = {
    groups: [],
  };

  componentDidMount() {
    const getAllGroups = this.groupsService.getAllGroups();
    getAllGroups.then((res) => {
      const groups = res.data;
      this.setState({ groups });
    });
    document.title = "Ads Cloud - Groups";
  }

  openUpdateGroupPage = (e) => {
    this.props.history.push({
      pathname: "/addUpdateGroup",
      state: { groupId: e.row.id },
    });
  };

  columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "fullName", headerName: "Full Name", width: 250 },
    { field: "groupType", headerName: "Group Type", width: 250 },
    {
      field: "limits",
      headerName: "Limits",
      type: "number",
      width: 250,
    },
  ];

  rows = [
    {
      id: 1,
      createdAt: null,
      fullName: "admin",
      groupType: "Admin",
      limits: 30,
      queueIds: null,
    },
  ];

  render() {
    return (
      <Container maxWidth="xl">
        <div>
          <Box bgcolor="grey">
            <Button
              style={{ margin: 10 }}
              variant="contained"
              color="primary"
              component={Link}
              to="/addUpdateGroup"
            >
              Add New Group
            </Button>
          </Box>
        </div>
        <div style={{ height: 650, width: "100%" }}>
          <DataGrid
            rows={this.state.groups}
            columns={this.columns}
            pageSize={9}
            onRowDoubleClick={this.openUpdateGroupPage}
            sortModel={[
              {
                field: "id",
                sort: "asc",
              },
            ]}
          />
        </div>
      </Container>
    );
  }
}

export default Groups;
