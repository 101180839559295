import axios from "axios";

export default class OrganizationService
{
  static myInstance = null;
  url = process.env.REACT_APP_BACKEND_URL + "orgProfile";
  static getInstance()
  {
    if(OrganizationService.myInstance == null)
    {
      this.myInstance = new OrganizationService();
    }
    return this.myInstance;
  }

  //ak should go away and should be read from token or session
  getOrgProfile() 
  {
   return axios.get(this.url);
  }

  updateOrgProfile(orgProfile)
  {
    var data = JSON.stringify(orgProfile);
    return axios.put(this.url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

}


