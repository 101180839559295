import { Box } from "@material-ui/core";

import React from "react";

import ADSBarChart from "./ADSBarChart";
import ADSPieChart from "./ADSPieChart";
import Grid from "@material-ui/core/Grid";
import UsageSummary from "./UsageSummary";
import EmailDropDown from "./EmailDropDown";
import AuthenticationService from "../login/AuthenticationService";
import jwt from "jwt-decode";

export default function Home() {
  const token = AuthenticationService.getInstance().getToken();
  const parsedToken = jwt(token);

  const [selectedEmail, setSelectedEmail] = React.useState(
    localStorage.getItem("reportSelectedEmail")
      ? localStorage.getItem("reportSelectedEmail")
      : parsedToken.sub
  );

  const handleEmailChange = (emailId) => {
    console.log(emailId);

    setSelectedEmail(emailId);
  };

  return (
    <div>
      {parsedToken.roles.indexOf("admin") !== -1 ? (
        <Box color="text.primary">
          <EmailDropDown
            onHandleEmailChange={handleEmailChange}
            loggedInUser={parsedToken.sub}
          ></EmailDropDown>
        </Box>
      ) : (
        ""
      )}
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <fieldset>
            <legend>Daily Usage - Time in Minutes</legend>
            <ADSBarChart
              selectedEmail={selectedEmail}
              duration={"daily"}
              displayKey="time"
            />
          </fieldset>
        </Grid>
        <Grid item xs={12} md={6}>
          <fieldset>
            <legend>Daily Usage - Cost in USD</legend>
            <ADSBarChart
              selectedEmail={selectedEmail}
              duration={"daily"}
              displayKey="cost"
            />
          </fieldset>
        </Grid>
        <Grid item xs={12} md={6}>
          <fieldset>
            <legend>Monthly Usage - Time in Minutes</legend>
            <ADSBarChart
              selectedEmail={selectedEmail}
              duration={"monthly"}
              displayKey="time"
            />
          </fieldset>
        </Grid>
        <Grid item xs={12} md={6}>
          <fieldset>
            <legend>Monthly Usage - Cost in USD</legend>
            <ADSBarChart
              selectedEmail={selectedEmail}
              duration={"monthly"}
              displayKey="cost"
            />
          </fieldset>
        </Grid>

        <Grid item xs={12} md={6}>
          <fieldset>
            <legend>Monthly Usage Queue</legend>
            <ADSPieChart
              selectedEmail={selectedEmail}
              duration={"monthly"}
              type={"usage"}
            />
          </fieldset>
        </Grid>
        <Grid item xs={12} md={6}>
          <fieldset>
            <legend>Monthly Cost by Queue</legend>
            <ADSPieChart
              selectedEmail={selectedEmail}
              duration={"monthly"}
              type={"cost"}
            />
          </fieldset>
        </Grid>
        <Grid item xs={12} md={6}>
          <fieldset>
            <legend>Daily Job Status</legend>
            <ADSPieChart
              selectedEmail={selectedEmail}
              duration={"daily"}
              type={"job"}
            />
          </fieldset>
        </Grid>

        <Grid item xs={12} md={6}>
          <fieldset>
            <legend>Usage Summary</legend>
            <UsageSummary selectedEmail={selectedEmail} />
          </fieldset>
        </Grid>
      </Grid>
    </div>
  );
}
