import React, { Component } from "react";
import UsersService from "./UsersService";
import { DataGrid } from "@material-ui/data-grid";
import { Container } from "@material-ui/core";

class Users extends Component {
  usersService = UsersService.getInstance();

  state = {
    users: [],
  };

  constructor(props) {
    super(props);
    this.openUpdateUserPage = this.openUpdateUserPage.bind(this);
  }

  componentDidMount() {
    const getAllUsers = this.usersService.getAllUsers();
    getAllUsers.then((res) => {
      const users = res.data;
      this.setState({ users });
    });
    document.title = "Ads Cloud - Users";
  }

  openUpdateUserPage = (e) => {
    this.props.history.push({
      pathname: "/updateUser",
      state: { userId: e.row.id },
    });
  };

  columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "username", headerName: "User Name", width: 180 },
    { field: "firstName", headerName: "First Name", width: 200 },
    { field: "lastName", headerName: "Last Name", width: 200 },
    { field: "email", headerName: "Email", width: 300 },
    {
      field: "enabled",
      headerName: "Enabled",
      width: 130,
    },
    {
      field: "costLimit",
      headerName: "Cost Limit (USD)",
      width: 200,
    },
    {
      field: "costLimitStartDate",
      headerName: "Cost Start Dt.",
      width: 170,
    },
    { field: "createdAt", headerName: "Created At", width: 200 },
  ];

  render() {
    return (
      <Container maxWidth="xl">
        <div style={{ height: 650, width: "100%" }}>
          <DataGrid
            rows={this.state.users}
            columns={this.columns}
            pageSize={9}
            onRowDoubleClick={this.openUpdateUserPage}
            sortModel={[
              {
                field: "id",
                sort: "asc",
              },
            ]}
          />
        </div>
      </Container>
    );
  }
}

export default Users;
