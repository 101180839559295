import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withRouter } from "react-router-dom";
import AuthenticationService from "./login/AuthenticationService";
import jwt from "jwt-decode";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import GroupIcon from "@material-ui/icons/Group";
import QueueIcon from "@material-ui/icons/Queue";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ContactsIcon from "@material-ui/icons/Contacts";
import DataUsageIcon from "@material-ui/icons/DataUsage";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import BusinessIcon from "@material-ui/icons/Business";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    [theme.breakpoints.down("xs")]: {
      flexGrow: 1,
    },
  },
  headerOptions: {
    display: "flex",
    flex: 1,
    justifyContent: "space-evenly",
  },
}));

var menuItems;

const calculateMenu = () => {
  const token = AuthenticationService.getInstance().getToken();
  const parsedToken = jwt(token);
  if (parsedToken.roles.indexOf("admin") === -1) {
    menuItems = [
      {
        menuTitle: "Home",
        pageURL: "/",
        matchURL: ["/"],
      },
      {
        menuTitle: "Profile",
        pageURL: "/profile",
        matchURL: ["/profile"],
      },
      {
        menuTitle: "Cluster Usage",
        pageURL: "/clusterUsage",
        matchURL: ["/clusterUsage"],
      },
    ];
  } else {
    menuItems = [
      {
        menuTitle: "Home",
        pageURL: "/",
        matchURL: ["/"],
      },
      {
        menuTitle: "Profile",
        pageURL: "/profile",
        matchURL: ["/profile"],
      },
      {
        menuTitle: "Organization",
        pageURL: "/organization",
        matchURL: ["/organization"],
      },
      {
        menuTitle: "Groups",
        pageURL: "/groups",
        matchURL: ["/groups", "/addUpdateGroup"],
      },
      {
        menuTitle: "Queues",
        pageURL: "/queues",
        matchURL: ["/queues", "/addUpdateQueue"],
      },
      {
        menuTitle: "Executables",
        pageURL: "/executables",
        matchURL: ["/executables", "/addUpdateExecutable"],
      },
      {
        menuTitle: "Users",
        pageURL: "/users",
        matchURL: ["/users", "/updateUser"],
      },
      {
        menuTitle: "Cluster Usage",
        pageURL: "/clusterUsage",
        matchURL: ["/clusterUsage"],
      },
    ];
  }
};

const Header = (props) => {
  const { history } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  calculateMenu();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClick = (pageURL) => {
    history.push(pageURL);
    setAnchorEl(null);
  };

  const handleButtonClick = (pageURL) => {
    history.push(pageURL);
  };
  const location = useLocation();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            ADS-CLOUD
          </Typography>
          {isMobile ? (
            <>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={handleMenu}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={() => setAnchorEl(null)}
              >
                {menuItems.map((menuItem) => {
                  const { menuTitle, pageURL } = menuItem;
                  return (
                    <MenuItem
                      key={menuTitle}
                      onClick={() => handleMenuClick(pageURL)}
                    >
                      {menuTitle}
                    </MenuItem>
                  );
                })}
              </Menu>
            </>
          ) : (
            <div className={classes.headerOptions}>
              {menuItems.map((menuItem) => {
                const { menuTitle, pageURL, matchURL } = menuItem;
                return (
                  <Button
                    // variant="contained"
                    // color="white"
                    // disableElevation
                    variant={
                      matchURL.indexOf(location.pathname) > -1
                        ? "contained"
                        : "text"
                    }
                    size="large"
                    style={
                      matchURL.indexOf(location.pathname) > -1
                        ? {}
                        : { color: "white" }
                    }
                    key={menuTitle}
                    onClick={() => handleButtonClick(pageURL)}
                    startIcon={
                      pageURL === "/groups" ? (
                        <GroupIcon />
                      ) : pageURL === "/queues" ? (
                        <QueueIcon />
                      ) : pageURL === "/profile" ? (
                        <AccountCircleIcon />
                      ) : pageURL === "/users" ? (
                        <ContactsIcon />
                      ) : pageURL === "/clusterUsage" ? (
                        <DataUsageIcon />
                      ) : pageURL === "/executables" ? (
                        <DirectionsRunIcon />
                      ) : pageURL === "/organization" ? (
                        <BusinessIcon />
                      ) : (
                        ""
                      )
                    }
                  >
                    {menuTitle}
                  </Button>
                );
              })}
              <Button
                size="large"
                style={{ color: "white" }}
                // variant="contained"
                onClick={() => handleButtonClick("/logout")}
                endIcon={<ExitToAppIcon />}
              >
                Logout
              </Button>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default withRouter(Header);
