import React from "react";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import EmailService from "./apis/EmailService";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  inputLabel: { fontSize: 14, fontWeight: "bold" },
}));

export default function EmailDropDown(props) {
  const classes = useStyles();
  const [emails, setEmails] = React.useState([]);
  const [selectedEmail, setSelectedEmail] = React.useState("");
  const handleChange = (event) => {
    if (selectedEmail !== event.target.value) {
      setSelectedEmail(event.target.value);
      props.onHandleEmailChange(event.target.value);
      localStorage.setItem("reportSelectedEmail", event.target.value);
    }
  };
  React.useEffect(() => {
    var emailsPromise = EmailService.getInstance().getAllEmails();
    emailsPromise.then((res) => {
      setEmails(res.data);
    });
  }, []);
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="demo-simple-select-label" className={classes.inputLabel}>
        Select User
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={
          selectedEmail === ""
            ? localStorage.getItem("reportSelectedEmail")
              ? localStorage.getItem("reportSelectedEmail")
              : props.loggedInUser
            : selectedEmail
        }
        label="Select User"
        onChange={handleChange}
      >
        <MenuItem value="All Users">All Users</MenuItem>
        {emails.map((email) => (
          <MenuItem value={email} key={email}>
            {email}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
