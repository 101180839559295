import axios from "axios";

export default class GroupsService
{
  static myInstance = null;
  url = process.env.REACT_APP_BACKEND_URL + "groups";
  queueUrl = process.env.REACT_APP_BACKEND_URL + "queues";
  static getInstance()
  {
    if(GroupsService.myInstance == null)
    {
      this.myInstance = new GroupsService();
    }
    return this.myInstance;
  }

  getAllGroups() 
  {
    return axios.get(this.url);
  }

  getGroup(groupId)
  {
    return axios.get(this.url + "/" + groupId);
  }

  getAllQueues() 
  {
    return axios.get(this.queueUrl);
  }

  updateGroup(group)
  {
    var data = JSON.stringify(group);
    axios.put(this.url, data, {
      headers: {
        "Content-Type": "application/json"
      },
    });
  }

  createGroup(group)
  {
    var data = JSON.stringify(group);
    return axios.post(this.url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  updateQueues(groupId, queueIds)
  {
    var data = JSON.stringify(queueIds);
    var mappingURL = this.url + groupId + 'queues'; 
    axios.put(mappingURL, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

}


