import React, { Component } from "react";
import ExecutablesService from "./ExecutablesService";
import { DataGrid } from "@material-ui/data-grid";
import { Container, Box, Button } from "@material-ui/core";
import { Link } from "react-router-dom";

class Executables extends Component {
  executablesService = ExecutablesService.getInstance();

  constructor(props) {
    super(props);
    this.openUpdateExecutablePage = this.openUpdateExecutablePage.bind(this);
  }

  state = {
    executables: [],
  };

  componentDidMount() {
    const getAllExecutables = this.executablesService.getAllExecutables();
    getAllExecutables.then((res) => {
      const executables = res.data;
      this.setState({ executables });
    });
    document.title = "Ads Cloud - Executables";
  }

  openUpdateExecutablePage = (e) => {
    this.props.history.push({
      pathname: "/addUpdateExecutable",
      state: { executableId: e.row.id },
    });
  };

  columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 250 },
    { field: "coreType", headerName: "Core Type", width: 200 },
    {
      field: "fileName",
      headerName: "File Name",
      width: 650,
    },
    { field: "coreType", headerName: "Core Type", width: 200 },
    {
      field: "enabled",
      headerName: "Enabled",
      width: 200,
    },
  ];

  rows = [
    {
      id: 1,
      createdAt: null,
      fullName: "admin",
      ExecutableType: "Admin",
      limits: 30,
      queueIds: null,
    },
  ];

  render() {
    return (
      <Container maxWidth="xl">
        <div>
          <Box bgcolor="grey">
            <Button
              style={{ margin: 10 }}
              variant="contained"
              color="primary"
              component={Link}
              to="/addUpdateExecutable"
            >
              Add New Executable
            </Button>
          </Box>
        </div>
        <div style={{ height: 650, width: "100%" }}>
          <DataGrid
            rows={this.state.executables}
            columns={this.columns}
            pageSize={9}
            onRowDoubleClick={this.openUpdateExecutablePage}
            sortModel={[
              {
                field: "id",
                sort: "asc",
              },
            ]}
          />
        </div>
      </Container>
    );
  }
}

export default Executables;
