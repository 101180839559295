import React from "react";

import ADSPieChart from "../ADSPieChart";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";

export default function DrillDown(props) {
  var duration = props.location.state.duration;
  duration = duration.charAt(0).toUpperCase() + duration.slice(1);
  let history = useHistory();
  function onBackButtonClick() {
    history.goBack();
  }

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <fieldset>
            <legend>
              {duration} Usage by User on {props.location.state.durationValue}
            </legend>
            <ADSPieChart
              duration={props.location.state.duration}
              durationValue={props.location.state.durationValue}
              type={"usage"}
            />
          </fieldset>
        </Grid>
        <Grid item xs={12} md={6}>
          <fieldset>
            <legend>
              {duration} Cost by User on {props.location.state.durationValue}
            </legend>
            <ADSPieChart
              duration={props.location.state.duration}
              durationValue={props.location.state.durationValue}
              type={"cost"}
            />
          </fieldset>
        </Grid>
        <Grid item xs={1} style={{ marginTop: "10px", marginLeft: "10px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={onBackButtonClick}
          >
            <ArrowBackIcon /> &nbsp;&nbsp;Back
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
