import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExecutablesService from "./ExecutablesService";
import { Container } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import ExecutablesTransferList from "./ExecutablesTransferList";
import { useLocation, useHistory } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";

const executablesService = ExecutablesService.getInstance();
var alert;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const AddUpdateExecutable = (props) => {
  const classes = useStyles();
  const [executable, setExecutable] = React.useState(0);
  const [executableFile, setExecutableFile] = React.useState(0);
  const location = useLocation();
  const history = useHistory();
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    if (location.state) {
      const updateExecutableId = location.state.executableId;
      if (updateExecutableId) {
        executablesService.getExecutable(updateExecutableId).then((res) => {
          setExecutable(res.data);
        });
      }
    }
  }, [location]);

  function setQueueId(ids) {
    var executableObj = { ...executable };
    executableObj["queueIds"] = ids;
    setExecutable(executableObj);
  }

  function getEnabledValue(enabledValue) {
    if (enabledValue === true) {
      return "true";
    }
    if (enabledValue === false) {
      return "false";
    }
    return "true";
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    executablesService
      .createExecutable(executable, executableFile, (event) => {
        setProgress(Math.round((100 * event.loaded) / event.total));
      })
      .then(() => history.push("/executables"))
      .catch((error) => {
        alert = <Alert severity="error">Error while saving Executable</Alert>;
        forceUpdate();
        alert = "";
      });
  };

  const cancelSave = () => {
    history.goBack();
  };

  const handleExecutableChange = (e) => {
    var executableObj = { ...executable };
    executableObj[e.target.name] = e.target.value;
    setExecutable(executableObj);
  };

  const handleFileChange = (e) => {
    var executableFileObj = { ...executableFile };
    executableFileObj[e.target.name] = e.target.files[0];
    setExecutableFile(executableFileObj);
    console.log(executableFileObj);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Container>
            <form id="userProfileForm" onSubmit={handleSubmit}>
              {alert}
              <TextField
                id="name"
                label="Name"
                placeholder="Name"
                variant="outlined"
                fullWidth
                margin="normal"
                name="name"
                InputLabelProps={{
                  shrink: true,
                }}
                value={executable.name || ""}
                onChange={handleExecutableChange}
              ></TextField>
              <TextField
                id="fileName"
                label="File Name"
                placeholder="File Name"
                variant="outlined"
                fullWidth
                margin="normal"
                name="fileName"
                InputLabelProps={{
                  shrink: true,
                }}
                value={executable.fileName || ""}
                onChange={handleExecutableChange}
              ></TextField>

              <FormControl
                variant="outlined"
                key="ExecutableType"
                margin="normal"
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-label">
                  Executable Type
                </InputLabel>
                <Select
                  id="coreType"
                  key="coreType"
                  label="Core Type"
                  placeholder="Core Type"
                  variant="outlined"
                  fullWidth
                  name="coreType"
                  value={executable.coreType || ""}
                  onChange={handleExecutableChange}
                >
                  <MenuItem value="gpu">
                    <em>gpu</em>
                  </MenuItem>
                  <MenuItem value="cpu">
                    <em>cpu</em>
                  </MenuItem>
                </Select>
                <FormHelperText>Select Executable Type</FormHelperText>
              </FormControl>

              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  key="ExecutableType"
                  className={classes.formControl}
                  margin="normal"
                >
                  <InputLabel id="demo-simple-select-label">Enabled</InputLabel>
                  <Select
                    id="enabled"
                    key="enabled"
                    label="Enabled"
                    placeholder="Enabled"
                    variant="outlined"
                    fullWidth
                    name="enabled"
                    value={getEnabledValue(executable.enabled)}
                    onChange={handleExecutableChange}
                  >
                    <MenuItem value="true">
                      <em>true</em>
                    </MenuItem>
                    <MenuItem value="false">
                      <em>false</em>
                    </MenuItem>
                  </Select>
                  <FormHelperText>Is Executable Enabled</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" component="label">
                  {executableFile["fileUpload"]
                    ? executableFile["fileUpload"].name
                    : "Upload File"}
                  <input
                    name="fileUpload"
                    type="file"
                    hidden
                    onChange={handleFileChange}
                  />
                </Button>
                <LinearProgress variant="determinate" color="primary" value={progress} />
              </Grid>

              <Button
                style={{ margin: 10 }}
                variant="contained"
                color="primary"
                type="submit"
              >
                Save
              </Button>
              <Button
                style={{ margin: 10 }}
                variant="contained"
                color="secondary"
                type="button"
                onClick={cancelSave}
              >
                Cancel
              </Button>
            </form>
          </Container>
        </Grid>
        <Grid item xs={6}>
          <AppBar position="static" color="transparent">
            <Toolbar variant="regular">
              <Typography variant="h5" color="inherit">
                Assign queues to the Executable
              </Typography>
            </Toolbar>
          </AppBar>
          <ExecutablesTransferList
            key={executable.queueIds}
            selectedQueueIds={executable.queueIds}
            setQueueId={setQueueId}
          ></ExecutablesTransferList>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddUpdateExecutable;
