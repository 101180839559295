import axios from "axios";

export default class UserProfileService
{
  static myInstance = null;
  url = process.env.REACT_APP_BACKEND_URL + "userprofile";
  static getInstance()
  {
    if(UserProfileService.myInstance == null)
    {
      this.myInstance = new UserProfileService();
    }
    return this.myInstance;
  }

  //ak should go away and should be read from token or session
  getUserProfile() 
  {
   return axios.get(this.url);
  }

  updateUserProfile(userProfile)
  {
    var data = JSON.stringify(userProfile);
    axios.put(this.url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

}


