import React from "react";
import { Route, Redirect } from "react-router-dom";
import jwt from "jwt-decode";

import AuthenticationService from "./AuthenticationService";

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const token = AuthenticationService.getInstance().getToken();
      if (!token) {
        // not logged in so redirect to login page with the return url
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }
      const parsedToken = jwt(token);

      // check if route is restricted by role
      if (roles && parsedToken.roles.indexOf(roles) === -1) {
        // role not authorised so redirect to home page
        return <Redirect to={{ pathname: "/" }} />;
      }

      // authorised so return component
      return <Component {...props} />;
    }}
  />
);

export default PrivateRoute;
