import axios from "axios";

export default class AuthenticationService {
  static myInstance = null;
  loginURL = process.env.REACT_APP_BACKEND_URL + "authenticate";
  registerURL = process.env.REACT_APP_BACKEND_URL + "register";
  forgotPasswordURL = process.env.REACT_APP_BACKEND_URL + "forgotPassword";
  resetPasswordURL = process.env.REACT_APP_BACKEND_URL + "resetPassword";

  static getInstance() {
    if (AuthenticationService.myInstance == null) {
      this.myInstance = new AuthenticationService();
    }
    return this.myInstance;
  }

  getToken() {
    return localStorage.getItem("userToken");
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("userToken");
  }

  //ak should go away and should be read from token or session
  authenticate(user) {
    var data = JSON.stringify(user);
    var promise = axios
      .post(this.loginURL, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        const token = response.headers.authorization;
        localStorage.setItem("userToken", token);
        return true;
      })
      .catch((error) => {
        return error.response;
      });
    return promise;
  }

  register(user) {
    var data = JSON.stringify(user);
    var promise = axios
      .post(this.registerURL, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return true;
      })
      .catch((error) => {
        return error.response;
      });
    return promise;
  }

  forgotPassword(name) {
    var promise = axios
      .post(this.forgotPasswordURL, "value=" + name)
      .then(() => {
        return true;
      })
      .catch((error) => {
        return error.response;
      });
    return promise;
  }

  resetPassword(user) {
    var data = JSON.stringify(user);
    var promise = axios
      .post(this.resetPasswordURL, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return true;
      })
      .catch((error) => {
        return error.response;
      });
    return promise;
  }
}
