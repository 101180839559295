import axios from "axios";

export default class UsersService
{
  static myInstance = null;
  url = process.env.REACT_APP_BACKEND_URL + "users";
  static getInstance()
  {
    if(UsersService.myInstance == null)
    {
      this.myInstance = new UsersService();
    }
    return this.myInstance;
  }

  //ak should go away and should be read from token or session
  getAllUsers() 
  {
   return axios.get(this.url);
  }

  getUser(userId)
  {
    return axios.get(this.url + "/" + userId);
  }

  updateUser(user)
  {
    var data = JSON.stringify(user);
    return axios.put(this.url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  enableUser(userId)
  {
    axios.put(this.url + "/enable/"+userId , {
        headers: {
          "Content-Type": "application/json",
        },
      });
  }

  disableUser(userId)
  {
    axios.put(this.url + "/disable/"+userId , {
        headers: {
          "Content-Type": "application/json",
        },
      });
  }

 
}


