import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GroupsService from "./GroupsService";
import { Container } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import GroupsTransferList from "./GroupsTransferList";
import { useLocation, useHistory } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import AppBar from "@material-ui/core/AppBar";

import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

const groupsService = GroupsService.getInstance();
var alert;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const AddUpdateGroup = (props) => {
  const classes = useStyles();
  const [group, setGroup] = React.useState(0);
  const location = useLocation();
  const history = useHistory();
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  React.useEffect(() => {
    if (location.state) {
      const updateGroupId = location.state.groupId;
      if (updateGroupId) {
        groupsService.getGroup(updateGroupId).then((res) => {
          setGroup(res.data);
        });
      }
    }
  }, [location]);

  function setQueueId(ids) {
    var groupObj = { ...group };
    groupObj["queueIds"] = ids;
    setGroup(groupObj);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    groupsService
      .createGroup(group)
      .then(() => history.push("/groups"))
      .catch((error) => {
        alert = <Alert severity="error">Error while saving groups</Alert>;
        forceUpdate();
        alert = "";
      });
  };

  const cancelSave = () => {
    history.goBack();
  };

  const handleGroupChange = (e) => {
    var groupObj = { ...group };
    groupObj[e.target.name] = e.target.value;
    setGroup(groupObj);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Container>
            <form id="userProfileForm" onSubmit={handleSubmit}>
              {alert}
              <TextField
                id="fullName"
                label="Full Name"
                placeholder="Full Name"
                variant="outlined"
                fullWidth
                margin="normal"
                name="fullName"
                InputLabelProps={{
                  shrink: true,
                }}
                value={group.fullName || ""}
                onChange={handleGroupChange}
              ></TextField>

              <FormControl
                variant="outlined"
                key="groupType"
                className={classes.formControl}
                margin="normal"
              >
                <InputLabel id="demo-simple-select-label">
                  Group Type
                </InputLabel>
                <Select
                  id="groupType"
                  key="groupType"
                  label="Group Type"
                  placeholder="Group Type"
                  variant="outlined"
                  fullWidth
                  name="groupType"
                  value={group.groupType || ""}
                  onChange={handleGroupChange}
                >
                  <MenuItem value="user">
                    <em>User</em>
                  </MenuItem>
                  <MenuItem value="admin">
                    <em>Admin</em>
                  </MenuItem>
                </Select>
                <FormHelperText>Select Group Type</FormHelperText>
              </FormControl>

              <TextField
                id="limits"
                label="Limits"
                placeholder="Limits"
                variant="outlined"
                fullWidth
                margin="normal"
                name="limits"
                InputLabelProps={{
                  shrink: true,
                }}
                value={group.limits || ""}
                onChange={handleGroupChange}
              ></TextField>
              <Button
                style={{ margin: 10 }}
                variant="contained"
                color="primary"
                type="submit"
              >
                Save
              </Button>
              <Button
                style={{ margin: 10 }}
                variant="contained"
                color="secondary"
                type="button"
                onClick={cancelSave}
              >
                Cancel
              </Button>
            </form>
          </Container>
        </Grid>
        <Grid item xs={6}>
          <AppBar position="static" color="transparent">
            <Toolbar variant="regular">
              <Typography variant="h5" color="inherit">
                Assign queues to the group
              </Typography>
            </Toolbar>
          </AppBar>
          <GroupsTransferList
            key={group.queueIds}
            selectedQueueIds={group.queueIds}
            setQueueId={setQueueId}
          ></GroupsTransferList>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddUpdateGroup;
