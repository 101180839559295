import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import QueuesService from "./QueuesService";
import { Container } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import QueuesTransferList from "./QueuesTransferList";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useHistory, useLocation } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import AppBar from "@material-ui/core/AppBar";

import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

const queuesService = QueuesService.getInstance();
var alert;
var updateQueueIdValue;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const AddUpdateQueue = () => {
  const history = useHistory();
  const classes = useStyles();
  const [queue, setQueue] = React.useState(0);
  const [instanceType, setInstanceType] = React.useState([]);
  const location = useLocation();
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  React.useEffect(() => {
    if (location.state) {
      const updateQueueId = location.state.queueId;
      updateQueueIdValue = updateQueueId;
      if (updateQueueId) {
        queuesService.getQueue(updateQueueId).then((res) => {
          setQueue(res.data);
        });
      }
    }
  }, [location]);

  function setGroupId(ids) {
    var queueObj = { ...queue };
    queueObj["groupIds"] = ids;
    setQueue(queueObj);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    queuesService
      .createQueue(queue)
      .then(() => goBack())
      .catch(() => {
        alert = <Alert severity="error">Error while saving queues</Alert>;
        forceUpdate();
        alert = "";
      });
  };

  const goBack = () => {
    let path = `queues`;
    history.push(path);
  };

  const handleQueueChange = (e) => {
    var tempQueue = { ...queue };
    tempQueue[e.target.name] = e.target.value;
    setQueue(tempQueue);
  };

  function getDefaultValue(id) {
    var tempQueue = { ...queue };
    if (id === "enabled") {
      tempQueue[id] = "true";
      setQueue(tempQueue);
      return "true";
    } else {
      tempQueue[id] = "50";
      setQueue(tempQueue);
      return "50";
    }
  }

  function getSelectFieldValues(key) {
    if (key === "coreType") {
      return ["gpu", "cpu"];
    }
    if (key === "computeType") {
      return ["spot", "ondemand"];
    }
    if (key === "enabled") {
      return ["true", "false"];
    }
    if (key === "instanceType") {
      if (instanceType.length === 0) {
        var res = queuesService.getAttribute("instance_type");
        console.log(res);
        setInstanceType(res);
      }
      return instanceType;

      //return [
      //  "t3.micro",
      //  "c5.18xlarge",
      //  "p3.2xlarge",
      //  "p3.8xlarge",
      //  "p3.16xlarge",
      //];
    }
  }

  const fields = [
    { type: "textField", id: "name", name: "Queue Name" },
    {
      type: "select",
      id: "coreType",
      name: "Core Type",
    },
    {
      type: "select",
      id: "computeType",
      name: "Compute Type",
    },
    {
      type: "select",
      id: "instanceType",
      name: "Instance Type",
    },
    { type: "textField", id: "minInstances", name: "Minimum Instances" },
    { type: "textField", id: "maxInstances", name: "Maximum Instances" },
    {
      type: "textField",
      id: "masterRootVolumeSize",
      name: "Master Root Volume Size (GB)",
    },
    {
      type: "textField",
      id: "computerRootVolumeSize",
      name: "Compute Root Volume Size (GB)",
    },
    {
      type: "select",
      id: "enabled",
      name: "User Enabled",
      values: ["true", "false"],
    },
  ];
  const queueObj = { ...queue };
  const contents = fields.map((field) =>
    field.type === "textField" ? (
      <TextField
        id={field.id}
        key={field.id}
        label={field.name}
        placeholder={field.name}
        variant="outlined"
        fullWidth
        margin="normal"
        name={field.id}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          readOnly:
            updateQueueIdValue && updateQueueIdValue !== -1 ? true : false,
        }}
        disabled={
          updateQueueIdValue && updateQueueIdValue !== -1 ? true : false
        }
        value={
          queueObj[field.id] ||
          (field.id === "masterRootVolumeSize" ||
          field.id === "computerRootVolumeSize"
            ? getDefaultValue(field.id)
            : queueObj[field.id] === 0
            ? "0"
            : "")
        }
        onChange={handleQueueChange}
      ></TextField>
    ) : (
      <FormControl
        variant="outlined"
        key={field.id}
        className={classes.formControl}
        disabled={
          updateQueueIdValue &&
          field.id !== "enabled" &&
          updateQueueIdValue !== -1
            ? true
            : false
        }
      >
        <InputLabel id="demo-simple-select-label">{field.name}</InputLabel>

        <Select
          id={field.id}
          key={field.id}
          label={field.name}
          placeholder={field.name}
          variant="outlined"
          disabled={
            updateQueueIdValue &&
            updateQueueIdValue !== -1 &&
            field.id !== "enabled"
              ? true
              : false
          }
          fullWidth
          name={field.id}
          value={
            queueObj[field.id] !== undefined
              ? queueObj[field.id]
              : field.id === "enabled"
              ? getDefaultValue(field.id)
              : ""
          }
          onChange={handleQueueChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {getSelectFieldValues(field.id).map((tempVal, i) => (
            <MenuItem key={tempVal} value={tempVal}>
              {tempVal}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Select {field.name}</FormHelperText>
      </FormControl>
    )
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Container>
            <form id="userProfileForm" onSubmit={handleSubmit}>
              {alert}
              {contents}
              <Button
                style={{ margin: 10 }}
                variant="contained"
                color="primary"
                type="submit"
              >
                Save
              </Button>
              <Button
                style={{ margin: 10 }}
                variant="contained"
                color="secondary"
                type="submit"
                onClick={goBack}
              >
                Cancel
              </Button>
            </form>
          </Container>
        </Grid>
        <Grid item xs={6}>
          <AppBar position="static" color="transparent">
            <Toolbar variant="regular">
              <Typography variant="h5" color="inherit">
                Assign groups to the queue
              </Typography>
            </Toolbar>
          </AppBar>
          <QueuesTransferList
            key={queue.groupIds}
            selectedGroupIds={queue.groupIds}
            setGroupId={setGroupId}
          ></QueuesTransferList>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddUpdateQueue;
