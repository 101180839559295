import React, { Component } from "react";
import QueuesService from "./QueuesService";
import { DataGrid } from "@material-ui/data-grid";
import { Container, Box, Button } from "@material-ui/core";

class Queues extends Component {
  queuesService = QueuesService.getInstance();

  state = {
    queues: [],
  };

  constructor(props) {
    super(props);
    this.openUpdateQueuePage = this.openUpdateQueuePage.bind(this);
  }

  componentDidMount() {
    const getAllQueues = this.queuesService.getAllQueues();
    getAllQueues.then((res) => {
      const queues = res.data;
      this.setState({ queues });
    });
    document.title = "Ads Cloud - Queues";
  }

  openUpdateQueuePage = (e) => {
    this.props.history.push({
      pathname: "/addUpdateQueue",
      state: { queueId: e.row.id },
    });
  };

  openAddQueuePage = (e) => {
    this.props.history.push({
      pathname: "/addUpdateQueue",
      state: { queueId: -1 },
    });
  };

  columns = [
    { field: "name", headerName: "Name", width: 300 },
    { field: "enabled", headerName: "Enabled", width: 129 },
    { field: "coreType", headerName: "Core Type", width: 142 },
    { field: "instanceType", headerName: "Instance Type", width: 165 },
    { field: "computeType", headerName: "Compute Type", width: 169 },
    {
      field: "minInstances",
      headerName: "Min Instances",
      type: "number",
      width: 165,
    },
    {
      field: "maxInstances",
      headerName: "Max Instances",
      type: "number",
      width: 169,
    },
    {
      field: "masterRootVolumeSize",
      headerName: "Master Vol. Size",
      type: "number",
      width: 180,
    },
    {
      field: "computerRootVolumeSize",
      headerName: "Compute Vol. Size",
      type: "number",
      width: 195,
    },
  ];

  render() {
    return (
      <Container maxWidth="xl">
        <div>
          <Box bgcolor="grey">
            <Button
              style={{ margin: 10 }}
              variant="contained"
              color="primary"
              onClick={this.openAddQueuePage}
            >
              Add New Queue
            </Button>
          </Box>
        </div>
        <div style={{ height: 650, width: "100%" }}>
          <DataGrid
            rows={this.state.queues}
            columns={this.columns}
            pageSize={9}
            onRowDoubleClick={this.openUpdateQueuePage}
            sortModel={[
              {
                field: "name",
                sort: "asc",
              },
            ]}
          />
        </div>
      </Container>
    );
  }
}

export default Queues;
