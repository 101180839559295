import AuthenticationService from "./AuthenticationService";

const Logout = () => {
  AuthenticationService.getInstance().logout();
  window.location.href = "/login";
  localStorage.clear();

  return <div></div>;
};

export default Logout;
