import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Container } from "@material-ui/core";
import OrganizationService from "./OrganizationService";
import { Alert } from "@material-ui/lab";

class Organization extends Component {
  organizationservice = OrganizationService.getInstance();

  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOrganizationChange = this.handleOrganizationChange.bind(this);
  }
  state = {
    organizationProfile: {},
    value: 0,
    alertMsg: "",
  };

  componentDidMount() {
    const orgProfile = this.organizationservice.getOrgProfile();
    orgProfile.then((res) => {
      const organizationProfile = res.data;
      this.setState({ organizationProfile: organizationProfile });
    });
    document.title = "Ads Cloud - Organization";
  }

  handleSubmit(event) {
    event.preventDefault();
    this.orgProfileSubmit(event.target);
  }

  handleOrganizationChange(event) {
    var eventObj = { ...this.state.organizationProfile };

    const value = event.target.value;
    const field = event.target.name;
    if (field === "costLimit" || field === "budgetWarnThreshold") {
      const onlyNums = value.replace(/[^0-9]/g, "");
      event.target.value = onlyNums;
    }
    eventObj[event.target.name] = event.target.value;
    this.setState({ organizationProfile: eventObj });
  }

  async orgProfileSubmit(formData) {
    const orgProfilePromise = this.organizationservice.updateOrgProfile(
      this.state.organizationProfile
    );
    orgProfilePromise
      .then(() => {
        this.setState({
          alertMsg: (
            <Alert severity="success">
              Organization details saved successfully
            </Alert>
          ),
        });
      })
      .catch(() => {
        this.setState({
          alertMsg: (
            <Alert severity="error">
              Some error occurred while saving organization details
            </Alert>
          ),
        });
      });
  }

  render() {
    return (
      <Container maxWidth="xl">
        <div>
          {this.state.alertMsg}
          <form id="userProfileForm" onSubmit={this.handleSubmit}>
            <TextField
              id="name"
              label="Organization Name"
              placeholder="Organization Name"
              variant="outlined"
              fullWidth
              margin="normal"
              name="name"
              InputLabelProps={{
                shrink: true,
              }}
              value={this.state.organizationProfile.name || ""}
              onChange={this.handleOrganizationChange}
            ></TextField>
            <TextField
              id="costLimit"
              label="Cost Limit (USD)"
              placeholder="Cost Limit"
              variant="outlined"
              fullWidth
              margin="normal"
              name="costLimit"
              InputLabelProps={{
                shrink: true,
              }}
              value={this.state.organizationProfile.costLimit || ""}
              onChange={this.handleOrganizationChange}
              // type="number"
            ></TextField>
            <TextField
              id="costLimitStartDate"
              label="Cost Limit Start Date (mm-dd-yyyy)"
              placeholder="Cost Limit Start Date"
              variant="outlined"
              fullWidth
              margin="normal"
              name="costLimitStartDate"
              InputLabelProps={{
                shrink: true,
              }}
              value={this.state.organizationProfile.costLimitStartDate || ""}
              onChange={this.handleOrganizationChange}
            ></TextField>

            <TextField
              id="budgetWarnThreshold"
              label="Low Budget Warning Threshold (%)"
              placeholder="Low Budget Warning Threshold"
              variant="outlined"
              fullWidth
              margin="normal"
              name="budgetWarnThreshold"
              InputLabelProps={{
                shrink: true,
              }}
              value={this.state.organizationProfile.budgetWarnThreshold || ""}
              onChange={this.handleOrganizationChange}
            ></TextField>

            <TextField
              id="serviceCost"
              label="Spent up to Yesterday (USD) (Information Only)"
              placeholder="Spent up to Yesterday"
              variant="outlined"
              fullWidth
              margin="normal"
              name="serviceCost"
              disabled="true"
              InputLabelProps={{
                shrink: true,
              }}
              value={this.state.organizationProfile.serviceCost || ""}
            ></TextField>

            <Button
              style={{ margin: 10 }}
              variant="contained"
              color="primary"
              type="submit"
            >
              Save
            </Button>
          </form>
        </div>
      </Container>
    );
  }
}

export default Organization;
