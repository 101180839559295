import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter as Router } from "react-router-dom";
import axios from 'axios';
import AuthenticationService  from './login/AuthenticationService';
import { createRoot } from "react-dom/client";



axios.interceptors.request.use(
  request => {
    const token = AuthenticationService.getInstance().getToken();
    if (token) {
        var bearerToken = "Bearer " + token;
        request.headers['Authorization'] = bearerToken;
        return request;
    }
    return request;
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error && error.response && [401, 403].indexOf(error.response.status) !== -1) {
      // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      AuthenticationService.getInstance().logout();
      window.location.href = "/login"
      console.log(error.response.status);
    }
    return Promise.reject(error);
  }
);

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
    <Router>
      <CssBaseline />
      <App />
    </Router>
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
