import React, { Component } from "react";
import ClusterUsageService from "./ClusterUsageService";
import { DataGrid } from "@material-ui/data-grid";
import { Container, Button, ButtonGroup } from "@material-ui/core";
import AuthenticationService from "../login/AuthenticationService";
import jwt from "jwt-decode";

class ClusterUsage extends Component {
  clusterUsageService = ClusterUsageService.getInstance();

  handleRowSelection = (e) => {
    const selectedRows = e;
    this.setState({ selectedRows });
  };

  handlePurge = () => {
    const getAllUsages = this.clusterUsageService.deleteUsage(
      this.state.selectedRows
    );
    getAllUsages.then((res) => {
      console.log(res);
      this.loadData();
    });
  };

  handleExport = () => {
    const getAllUsages = this.clusterUsageService.exportUsage();
    getAllUsages.then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "clusterUsage.csv"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  state = {
    clusterUsages: [],
    selectedRows: [],
  };

  loadData() {
    const getAllUsages = this.clusterUsageService.getAllUsage();
    getAllUsages.then((res) => {
      const clusterUsages = res.data;
      this.setState({ clusterUsages });
    });
  }

  componentDidMount() {
    this.loadData();
    document.title = "Ads Cloud - Cluster Usage";
  }

  columns = [
    { field: "id", headerName: "ID", width: 95, hide: true },
    { field: "jobName", headerName: "Job Name", width: 250 },
    { field: "userName", headerName: "User Name", width: 150 },
    { field: "queueName", headerName: "Queue Name", width: 200 },
    { field: "jobStatus", headerName: "Job Status", width: 150 },
    { field: "storageLoc", headerName: "Storage Loc", width: 160 },
    { field: "executable", headerName: "Executable", width: 150 },
    { field: "cost", headerName: "Cost", width: 120 },
    { field: "startTime", headerName: "Start Time", width: 160 },
    { field: "endTime", headerName: "End Time", width: 160 },
  ];

  getPurgeButton() {
    const token = AuthenticationService.getInstance().getToken();
    const parsedToken = jwt(token);
    if (parsedToken.roles.indexOf("admin") !== -1) {
      return (
        <Button color="primary" onClick={this.handlePurge}>
          Purge
        </Button>
      );
    }
  }

  render() {
    return (
      <Container maxWidth="xl">
        <div style={{ height: 650, width: "100%" }}>
          <DataGrid
            autoHeight
            checkboxSelection
            rows={this.state.clusterUsages}
            columns={this.columns}
            pageSize={10}
            rowsPerPageOptions={[5, 10, 20]}
            onSelectionModelChange={this.handleRowSelection}
            sortModel={[
              {
                field: "startTime",
                sort: "desc",
              },
            ]}
          />
        </div>
        <ButtonGroup
          variant="contained"
          aria-label="contained primary button group"
        >
          {this.getPurgeButton()}
          <Button color="primary" onClick={this.handleExport}>
            Export
          </Button>
        </ButtonGroup>
      </Container>
    );
  }
}

export default ClusterUsage;
