import axios from "axios";

export default class UsageSummaryService {
  static myInstance = null;
  url = process.env.REACT_APP_BACKEND_URL + "reports/";

  static getInstance() {
    if (UsageSummaryService.myInstance == null) {
      this.myInstance = new UsageSummaryService();
    }
    return this.myInstance;
  }

  getUsageSummary(username) {
    if (username === "" || username === "All Users")
      return axios.get(this.url + "usageSummary");
    else return axios.get(this.url + "usageSummary/" + username);
  }
}
