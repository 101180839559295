import axios from "axios";

export default class QueuesService
{
  static myInstance = null;
  url = process.env.REACT_APP_BACKEND_URL + "queues";

  attributeUrl = process.env.REACT_APP_BACKEND_URL + "attributes";
  
  static getInstance()
  {
    if(QueuesService.myInstance == null)
    {
      this.myInstance = new QueuesService();
    }
    return this.myInstance;
  }

  //ak should go away and should be read from token or session
  getAllQueues() 
  {
   return axios.get(this.url);
  }

  getQueue(queueId)
  {
    return axios.get(this.url + "/" + queueId);
  }

  updateQueue(queue)
  {
    var data = JSON.stringify(queue);
    axios.put(this.url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  createQueue(queue)
  {
    var data = JSON.stringify(queue);
    return axios.post(this.url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });

  }

  getAttributeAxios(key)
  {
    var res = axios.get(this.attributeUrl + "/" + key, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log(res);
    return res;
  }

  getAttribute(key)
  {
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open( "GET", this.attributeUrl + "/" + key, false ); // false for synchronous request
    xmlHttp.send( null );
    var res = xmlHttp.responseText;
    return JSON.parse(res);
  }

 

  

}


