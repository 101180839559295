import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import UserProfileService from "./UserProfileService";
import { Container } from "@material-ui/core";

class Profile extends Component {
  userProfileservice = UserProfileService.getInstance();

  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUserProfileChange = this.handleUserProfileChange.bind(this);
  }
  state = {
    userProfile: {},
  };

  componentDidMount() {
    const userProfile = this.userProfileservice.getUserProfile();
    userProfile.then((res) => {
      const userProfile = res.data;
      this.setState({ userProfile });
    });
    document.title = "Ads Cloud - Profile";
  }

  handleSubmit(event) {
    event.preventDefault();
    this.userProfileSubmit(event.target);
  }

  handleUserProfileChange(event) {
    var eventObj = { ...this.state.userProfile };
    eventObj[event.target.name] = event.target.value;
    this.setState({ userProfile: eventObj });
  }

  async userProfileSubmit(formData) {
    this.userProfileservice.updateUserProfile(this.state.userProfile);
  }

  render() {
    return (
      <Container maxWidth="xl">
        <div>
          <form id="userProfileForm" onSubmit={this.handleSubmit}>
            <TextField
              id="firstName"
              label="First Name"
              placeholder="First Name"
              variant="outlined"
              fullWidth
              margin="normal"
              name="firstName"
              InputLabelProps={{
                shrink: true,
              }}
              value={this.state.userProfile.firstName || ""}
              onChange={this.handleUserProfileChange}
            ></TextField>
            <TextField
              id="lastName"
              label="Last Name"
              placeholder="Last Name"
              variant="outlined"
              fullWidth
              margin="normal"
              name="lastName"
              InputLabelProps={{
                shrink: true,
              }}
              value={this.state.userProfile.lastName || ""}
              onChange={this.handleUserProfileChange}
            ></TextField>
            <TextField
              id="email"
              label="Email"
              placeholder="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              name="email"
              InputLabelProps={{
                shrink: true,
              }}
              value={this.state.userProfile.email || ""}
              onChange={this.handleUserProfileChange}
            ></TextField>
            <TextField
              id="userName"
              label="User Name"
              placeholder="User Name"
              variant="outlined"
              fullWidth
              margin="normal"
              name="username"
              InputLabelProps={{
                shrink: true,
              }}
              value={this.state.userProfile.username || ""}
              /*onChange={this.handleUserProfileChange}*/
            ></TextField>
            <TextField
              id="password"
              label="Reset Password"
              type="text"
              placeholder="Reset Password"
              variant="outlined"
              name="password"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              value={this.state.userProfile.password || ""}
              onChange={this.handleUserProfileChange}
            ></TextField>
            <TextField
              id="keys"
              label="SSH Public Key"
              type="text"
              placeholder="SSH Public Key"
              variant="outlined"
              name="keys"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              value={this.state.userProfile.keys || ""}
              onChange={this.handleUserProfileChange}
            ></TextField>
            <Button
              style={{ margin: 10 }}
              variant="contained"
              color="primary"
              type="submit"
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.getProfile}
            >
              Reset
            </Button>
          </form>
        </div>
      </Container>
    );
  }
}

export default Profile;
