import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import UsersService from "./UsersService";
import { Container } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import QueuesTransferList from "../queues/QueuesTransferList";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useHistory, useLocation } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

const usersService = UsersService.getInstance();
var alert;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const AddUpdateUser = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [user, setUser] = React.useState(0);
  const location = useLocation();
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  React.useEffect(() => {
    if (location.state) {
      const updateUserId = location.state.userId;
      if (updateUserId) {
        usersService.getUser(updateUserId).then((res) => {
          setUser(res.data);
        });
      }
    }
  }, [location]);

  function setGroupId(ids) {
    var userObj = { ...user };
    userObj["groupIds"] = ids;
    setUser(userObj);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    usersService
      .updateUser(user)
      .then(() => goBack())
      .catch(() => {
        alert = <Alert severity="error">Error while saving user</Alert>;
        forceUpdate();
        alert = "";
      });
  };

  const goBack = () => {
    let path = `users`;
    history.push(path);
  };

  const handleUserChange = (e) => {
    var tempUser = { ...user };
    const value = e.target.value;
    const field = e.target.name;
    if (field === "costLimit") {
      const onlyNums = value.replace(/[^0-9]/g, "");
      e.target.value = onlyNums;
    }
    tempUser[e.target.name] = e.target.value;
    setUser(tempUser);
  };

  const fields = [
    { type: "textField", id: "firstName", name: "First Name" },
    { type: "textField", id: "lastName", name: "Last Name" },
    { type: "textField", id: "username", name: "User Name", disabled: true },
    {
      type: "select",
      id: "enabled",
      name: "User Enabled",
      values: ["true", "false"],
    },
    { type: "textField", id: "email", name: "Email" },
    { type: "textField", id: "targetLaunchNode", name: "Target Launch Node" },
    { type: "textField", id: "costLimit", name: "Cost Limit (USD)" },
    {
      type: "textField",
      id: "costLimitStartDate",
      name: "Cost Limit Start Date (mm-dd-yyyy)",
    },
  ];
  const userObj = { ...user };
  const contents = fields.map((field) =>
    field.type === "textField" ? (
      <TextField
        id={field.id}
        key={field.id}
        label={field.name}
        placeholder={field.name}
        disabled={field.disabled === true ? "disabled" : ""}
        variant="outlined"
        fullWidth
        margin="normal"
        name={field.id}
        InputLabelProps={{
          shrink: true,
        }}
        value={userObj[field.id] || ""}
        onChange={handleUserChange}
      ></TextField>
    ) : (
      <FormControl
        variant="outlined"
        key={field.id}
        className={classes.formControl}
      >
        <InputLabel id="demo-simple-select-label">{field.name}</InputLabel>
        <Select
          id={field.id}
          key={field.id}
          label={field.name}
          placeholder={field.name}
          variant="outlined"
          fullWidth
          name={field.id}
          value={userObj[field.id] || "false"}
          onChange={handleUserChange}
        >
          {field.values.map((tempVal, i) => (
            <MenuItem key={tempVal} value={tempVal}>
              {tempVal}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Select {field.name}</FormHelperText>
      </FormControl>
    )
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Container>
            <form id="userProfileForm" onSubmit={handleSubmit}>
              {alert}
              {contents}
              <Button
                style={{ margin: 10 }}
                variant="contained"
                color="primary"
                type="submit"
              >
                Save
              </Button>
              <Button
                style={{ margin: 10 }}
                variant="contained"
                color="secondary"
                type="submit"
                onClick={goBack}
              >
                Cancel
              </Button>
            </form>
          </Container>
        </Grid>
        <Grid item xs={6}>
          <AppBar position="static" color="secondary.main">
            <Toolbar variant="regular">
              <Typography variant="h5" color="inherit">
                Assign groups to the user
              </Typography>
            </Toolbar>
          </AppBar>
          <QueuesTransferList
            key={user.groupIds}
            selectedGroupIds={user.groupIds}
            setGroupId={setGroupId}
          ></QueuesTransferList>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddUpdateUser;
