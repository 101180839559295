import React from "react";
import { useHistory } from "react-router-dom";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import BarChartService from "./apis/BarChartService";

export default function ADSBarChart(props) {
  let history = useHistory();
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    var dataPromise = BarChartService.getInstance().getUsageReport(
      props.selectedEmail,
      props.duration
    );
    dataPromise.then((res) => {
      setData(res.data);
    });
  }, [props.selectedEmail, props.duration]);

  function onClickDrillDown(e) {
    var selectedEmail = localStorage.getItem("reportSelectedEmail");
    if (e && (selectedEmail === "" || selectedEmail === "All Users")) {
      history.push({
        pathname: "home/drilldown",
        state: {
          duration: props.duration,
          durationValue: e.activePayload[0].payload.name,
        },
      });
    }
  }

  return (
    <BarChart
      width={500}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
      onClick={onClickDrillDown}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="displName" />
      <YAxis type="number" domain={[0, (dataMax) => Number(dataMax) + 50]} />
      <Tooltip />
      <Legend />
      <Bar dataKey={props.displayKey} fill="#8884d8" />
    </BarChart>
  );
}
