import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import AuthenticationService from "./AuthenticationService";
import Modal from "@material-ui/core/Modal";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="/">
        ADS-CLOUD
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const authService = AuthenticationService.getInstance();

var alert;

export default function ResetPassword(props) {
  const classes = useStyles();

  const [user, setUser] = React.useState(0);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    window.location.href = "/";
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !user["newPassword"] ||
      user["newPassword"] !== user["repeatPassword"]
    ) {
      alert = (
        <Alert severity="error">
          New password and repeat password does not match.
        </Alert>
      );
      forceUpdate();
      return;
    }
    var value = new URLSearchParams(props.location.search);
    user["email"] = value.get("email");
    user["token"] = value.get("token");
    const promise = authService.resetPassword(user);
    promise.then((response) => {
      if (response === true) {
        handleOpen();
      } else {
        alert = <Alert severity="error">{response.data}</Alert>;
        forceUpdate();
      }
    });
  };

  const handleLoginChange = (e) => {
    var oldUser = { ...user };
    oldUser[e.target.name] = e.target.value;
    setUser(oldUser);
  };

  function rand() {
    return Math.round(Math.random() * 20) - 10;
  }

  function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const modalUseStyle = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

  const [modalStyle] = React.useState(getModalStyle);
  const modalClasses = modalUseStyle();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          {alert}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div style={modalStyle} className={modalClasses.paper}>
              <h2 id="simple-modal-title">Reset Password</h2>
              <p id="simple-modal-description">
                Password successfully updated.
              </p>
            </div>
          </Modal>

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="newPassword"
            label="New Password"
            name="newPassword"
            autoComplete="newPassword"
            autoFocus
            type="password"
            onChange={handleLoginChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="repeatPassword"
            label="Repeat Password"
            name="repeatPassword"
            autoComplete="repeatPassword"
            type="password"
            onChange={handleLoginChange}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Reset Password
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
