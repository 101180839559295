import axios from "axios";

export default class ClusterUsageService
{
  static myInstance = null;
  url = process.env.REACT_APP_BACKEND_URL + "clusterUsage";
  deleteURL = process.env.REACT_APP_BACKEND_URL + "deleteClusterUsage";
  exportURL = process.env.REACT_APP_BACKEND_URL + "exportClusterUsage";


  static getInstance()
  {
    if(ClusterUsageService.myInstance == null)
    {
      this.myInstance = new ClusterUsageService();
    }
    return this.myInstance;
  }

  getAllUsage() 
  {
    var d = new Date();
    var n = d.getTimezoneOffset();
    return axios.get(this.url + "?timezone=" + n);
  }

  deleteUsage(ids)
  {
    
    return axios.delete(this.deleteURL + "?ids=" + ids.toString());
  }

  exportUsage()
  {
    var d = new Date();
    var n = d.getTimezoneOffset();
    return axios({
      url: this.exportURL + "?timezone=" + n,
      method: 'GET',
      responseType: 'blob', // important
    });
  }


}


