import axios from "axios";

export default class ExecutablesService
{
  static myInstance = null;
  url = process.env.REACT_APP_BACKEND_URL + "executables";
  queueUrl = process.env.REACT_APP_BACKEND_URL + "queues";
  static getInstance()
  {
    if(ExecutablesService.myInstance == null)
    {
      this.myInstance = new ExecutablesService();
    }
    return this.myInstance;
  }

  getAllExecutables() 
  {
    return axios.get(this.url);
  }

  getExecutable(executableId)
  {
    return axios.get(this.url + "/" + executableId);
  }

  getAllQueues() 
  {
    return axios.get(this.queueUrl);
  }

  updateExecutable(executable)
  {
    var data = JSON.stringify(executable);
    axios.put(this.url, data, {
      headers: {
        "Content-Type": "application/json"
      },
    });
  }

  createExecutable(executable, file, onUploadProgress)
  {
    var formData = new FormData();
    formData.append("data", JSON.stringify(executable));
    if(file)
    {
      formData.append("file", file["fileUpload"]);
    }
    return axios.post(this.url, formData, {
      headers: {
        "Content-Type": "multipart/form-data ; charset=utf-8",
      },
      onUploadProgress,
    });
  }

  updateQueues(executableId, queueIds)
  {
    var data = JSON.stringify(queueIds);
    var mappingURL = this.url + executableId + 'queues'; 
    axios.put(mappingURL, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}
