import React from "react";
import "./styles.css";
import Profile from "./profile/Profile";
import Groups from "./groups/Groups"
import Header from "./Header";
import AddUpdateGroup from "./groups/AddUpdateGroup"
import UpdateUser from "./user/UpdateUser"
import { Route, Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Queues from "./queues/Queues"
import AddUpdateQueue from "./queues/AddUpdateQueue"
import LoginPage from "./login/LoginPage"
import ForgotPassword from "./login/ForgotPassword"
import ResetPassword from "./login/ResetPassword"
import Logout from "./login/Logout"
import AuthenticationService  from './login/AuthenticationService';
import PrivateRoute from "./login/PrivateRoute"
import Users from "./user/Users";
import ClusterUsage from "./clusterUsage/ClusterUsage";
import Registration from "./login/Registration";
import Executables from "./executables/Executables"
import AddUpdateExecutable from "./executables/AddUpdateExecutable"
import Home from "./home/Home";
import DrillDown from "./home/drilldown/DrillDown"
import Organization from "./organization/Organization"

const useStyles = makeStyles({});

export default function App() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
     { window.location.pathname !== '/login' && AuthenticationService.getInstance().getToken() ? <Header /> : "" }
      
      <Switch>
        <PrivateRoute roles="" exact from="/" component={Home} />
        <PrivateRoute roles="" exact from="/profile" component={Profile} />
        <PrivateRoute roles="admin" exact from="/groups" component={Groups} />
        <PrivateRoute roles="admin" exact from="/executables" component={Executables} />
        <PrivateRoute roles="admin" exact from="/addUpdateExecutable" component={AddUpdateExecutable} />
        <PrivateRoute roles="admin" exact from="/queues" component={Queues} />
        <PrivateRoute roles="admin" exact from="/users" component={Users} />
        <PrivateRoute roles="" exact from="/clusterUsage" component={ClusterUsage} />
        <PrivateRoute roles="admin" exact from="/addUpdateGroup" component={AddUpdateGroup} />
        <PrivateRoute roles="admin" exact from="/addUpdateQueue" component={AddUpdateQueue} />
        <PrivateRoute roles="admin" exact from="/updateUser" component={UpdateUser} />
        <Route exact path="/login" render={props => <LoginPage {...props} />} />
        <Route exact path="/register" render={props => <Registration {...props} />} />
        <Route exact path="/forgotPassword" render={props => <ForgotPassword {...props} />}/>
        <Route exact path="/resetPassword" render={props => <ResetPassword {...props} />}/>
        <PrivateRoute roles="" exact from="/logout" component={Logout} />
        <PrivateRoute roles="" exact from="/home/drilldown" component={DrillDown} />
        <PrivateRoute roles="admin" exact from="/organization" component={Organization} />
      </Switch>
      
    </div>
  );
}