import axios from "axios";

export default class PieChartService {
  static myInstance = null;

  url =
    (window.location.origin.toString().includes("localhost")
      ? ""
      : window.location.origin.toString() + "/") +
    process.env.REACT_APP_BACKEND_URL +
    "reports/";

  static getInstance() {
    if (PieChartService.myInstance == null) {
      this.myInstance = new PieChartService();
    }
    return this.myInstance;
  }

  getDrillDownReport(duration, durationValue, type) {
    if (type === "usage" && duration === "daily") {
      return axios.get(this.url + "dailyUsageByUser/" + durationValue);
    }
    if (type === "usage" && duration === "monthly") {
      return axios.get(this.url + "monthlyUsageByUser/" + durationValue);
    }
    if (type === "cost" && duration === "daily") {
      return axios.get(this.url + "dailyCostByUser/" + durationValue);
    }
    if (type === "cost" && duration === "monthly") {
      return axios.get(this.url + "monthlyCostByUser/" + durationValue);
    }
  }

  getReport(username, duration, type) {
    if (duration === "daily" && type === "job") {
      if (username === "" || username === "All Users")
        return axios.get(this.url + "dailyjobstatus");
      else return axios.get(this.url + "dailyjobstatus/" + username);
    } else if (duration === "monthly") {
      if (type === "cost") {
        if (username === "" || username === "All Users")
          return axios.get(this.url + "currentmonthcost");
        else return axios.get(this.url + "currentmonthcost/" + username);
      } else if (type === "usage") {
        if (username === "" || username === "All Users")
          return axios.get(this.url + "currentmonthusage");
        else return axios.get(this.url + "currentmonthusage/" + username);
      }
    }
  }
}
